<template>
  <ui-modal v-model="isVisible">
    <div class="video-wrapper">
      <div class="close-box" @click="isVisible = false">
        <div class="one"></div>
        <div class="two"></div>
      </div>
      <lazy-video-component-videodfpromo v-if="src === 'promo-ru'" />
      <lazy-video-component-videopromonew
        v-else-if="src === 'promo'"
      />
      <lazy-video-component-videodfpromoytfull
        v-else-if="src === 'promo-yt'"
      />
      <lazy-video-component-video3dtour v-else-if="src === '3d'" />
      <lazy-video-component-videointeriorindf
        v-else-if="src === 'video'"
      />
      <lazy-video-component-videointeractivetour
        v-else-if="src === 'interactive-tour'"
      />
    </div>
  </ui-modal>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import UiModal from '~/components/Ui/UiModal.vue';
import {computed} from '#imports';

const props = withDefaults(
  defineProps<{
    show?: boolean;
    video?: string;
  }>(),
  {
    show: false,
    video: 'promo',
  },
);

const emits = defineEmits(['update:show']);

const isVisible = computed({
  get() {
    return props.show;
  },
  set(value) {
    emits('update:show', value);
  },
});

const src = ref(props.video);
</script>

<style lang="scss" scoped>
.video-wrapper {
  display: flex;
  background: linear-gradient(
    165.47deg,
    #1e90ea -6.68%,
    #005ba4 111.75%
  );
  max-height: inherit;
  // max-width: 90%;
  position: relative;
}
.close-box {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 48px;
  height: 48px;
  background: rgba(75, 84, 91, 0.3);

  .one {
    z-index: 101;
    position: absolute;
    height: 3px;
    width: 32px;
    left: 20%;
    top: 50%;
    transform: rotateZ(45deg);
    background-color: #fff;
    opacity: 0.7;
  }
  .two {
    z-index: 101;
    position: absolute;
    height: 3px;
    width: 32px;
    left: 20%;
    top: 50%;
    transform: rotateZ(135deg);
    background-color: #fff;
    opacity: 0.7;
  }
}

.promo-video {
  width: 100%;
}
</style>
